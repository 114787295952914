import React from "react"
import Layout from "../components/Layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Inscrições" />
  </Layout>
)

export default IndexPage
